const heading = {
  color: "text",
  fontFamily: "heading",
  lineHeight: "1.2",
  fontWeight: "heading",
}

export const base = {
  breakpoints: ["40em", "56em", "64em"],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "inherit",
    monospace: "Menlo, monospace",
  },
  fontSizes: [12, 14, 16, 20, 24, 36, 48, 64, 96],
  fontWeights: {
    body: 300,
    heading: 400,
    bold: 600,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: "#000",
    background: "#fff",
    primary: "#F00",
    secondary: "#F77",
    accent: "#639",
    gray: "#5A5A5A",
    lightgray: "#F6F6F6",
    legend: "#333",
  },
  text: {
    sectionTitle1: {
      fontFamily: "heading",
      color: "gray",
      fontSize: [4, 5],
      fontWeights: 300,
      // textTransform: 'uppercase',
      letterSpacing: "caps",
    },
    sectionTitle2: {
      fontFamily: "heading",
      color: "gray",
      fontSize: [3, 4],
      fontWeights: 300,
      // textTransform: 'uppercase',
      letterSpacing: "caps",
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: "body",
    },
    h1: {
      ...heading,
      fontSize: [4, 4, 5],
    },
    h2: {
      ...heading,
      fontSize: [3, 3, 4],
    },
    h3: {
      ...heading,
      fontSize: [2, 2, 3],
    },
    h4: {
      ...heading,
      fontSize: [1, 1, 2],
    },
    h5: {
      ...heading,
      fontSize: 1,
    },
    h6: {
      ...heading,
      fontSize: 0,
    },
    p: {
      color: "text",
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
    },
    a: {
      color: "primary",
    },
    pre: {
      fontFamily: "monospace",
      overflowX: "auto",
      code: {
        color: "inherit",
      },
    },
    code: {
      fontFamily: "monospace",
      fontSize: "inherit",
    },
    table: {
      width: "100%",
      borderCollapse: "separate",
      borderSpacing: 0,
    },
    th: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    td: {
      textAlign: "left",
      borderBottomStyle: "solid",
    },
    img: {
      maxWidth: "100%",
    },
    strong: {
      fontFamily: "body",
      lineHeight: "body",
      fontWeight: 500,
    },
  },
}

export default base
